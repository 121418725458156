import React from 'react'
import { connect } from 'react-redux'
import { TwitterIcon, FacebookIcon } from 'react-share'
import { FacebookProvider, ShareButton } from 'react-facebook'
import { get } from 'lodash/fp'
import { Flex } from 'src/components/system'
import { SVG } from 'src/components/SVG'
import { Link } from 'src/components/Link'
import { ButtonCircle } from 'src/components/ButtonCircle'
import { ReactComponent as AssetIconFacebookSVG } from 'src/assets/icon-facebook.svg'
import { ReactComponent as AssetIconTwitterSVG } from 'src/assets/icon-twitter.svg'
import { ReactComponent as AssetIconMailSVG } from 'src/assets/icon-mail.svg'
import Loader from 'react-loader-spinner'

export const ShareButtons = props => {
  const message = 'Share your somewhat heartfelt message.'
  const photoID = get('photoID', props)
  const isLoading = get('isLoading', props)
  const shareUrl = `https://greetings.walltowall.com/share?a=${photoID}`

  return (
    <Flex justifyContent="center" {...props}>
      {isLoading ? (
        <ButtonCircle minWidth={['4rem', '6rem']} mr={[2, 4]}>
          <Loader type="Rings" color="#fff" />
        </ButtonCircle>
      ) : (
        <FacebookProvider appId="1662050167345033">
          <ButtonCircle
            as={ShareButton}
            href={shareUrl}
            display="popup"
            minWidth={['4rem', '6rem']}
            mr={[2, 4]}
          >
            <SVG
              svg={AssetIconFacebookSVG}
              x={54.95}
              y={100.86}
              fill={'white'}
              width={['1rem', '2rem']}
            />
          </ButtonCircle>
        </FacebookProvider>
      )}
      {isLoading ? (
        <ButtonCircle minWidth={['4rem', '6rem']}>
          <Loader type="Rings" color="#fff" />
        </ButtonCircle>
      ) : (
        <ButtonCircle
          as={Link}
          to={`https://twitter.com/intent/tweet?url=${encodeURI(
            shareUrl
          )}&via=walltowall&hashtags=walltowallstudios&text=${encodeURI(
            message
          )}`}
          minWidth={['4rem', '6rem']}
        >
          <SVG
            svg={AssetIconTwitterSVG}
            x={98.48}
            y={80.03}
            fill="white"
            width={['2rem', '3rem']}
          />
        </ButtonCircle>
      )}
    </Flex>
  )
}

const mapStateToProps = state => ({
  ...state.output,
})

export const ShareButtonsWrapper = connect(mapStateToProps)(ShareButtons)

{
  /*
<FacebookProvider appId="1662050167345033">
  <ShareButton href="http://github.com">
    <FacebookIcon size={32} round />
  </ShareButton>
</FacebookProvider>
*/
}
{
  /* <ButtonCircle
  as={Link}
  to={`https://twitter.com/intent/tweet?url=http%3A%2F%2Fwtw-4268.netlify.com/a/${photoID}&via=walltowall&hashtags=walltowallstudios&text=${encodeURI(
    message
  )}`}
  minWidth={['4rem', '6rem']}
  mr={[2, 4]}
>
  <SVG
    svg={AssetIconMailSVG}
    x={98.48}
    y={80.03}
    fill="white"
    width={['2rem', '3rem']}
  />
</ButtonCircle> */
}
