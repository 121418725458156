import React, { useState, useEffect } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Redirect } from '@reach/router'
import html2canvas from 'html2canvas'
import axios from 'axios'
import { get, compose, isEmpty } from 'lodash/fp'

import { setPhotoURL } from 'src/modules/output'
import { setPhotoID } from 'src/modules/output'
import { Box, Image, Flex, Heading } from 'src/components/system'
import { Button } from 'src/components/Button'
import { Layout } from 'src/components/Layout'
import { Link } from 'src/components/Link'
import { OutputRenderer } from 'src/components/OutputRenderer'
import { RuleCurly } from 'src/components/RuleCurly'
import { ShareButtonsWrapper } from 'src/components/ShareButtonsWrapper'

const SendPageBase = ({ hasNoMagnets, setPhotoURL, setPhotoID, ...props }) => {
  const [isLoading, setIsLoading] = useState(true)
  const [previewImageURL, setPreviewImageURL] = useState(null)

  let imageDataURL = ''

  if (hasNoMagnets) return <Redirect noThrow={true} to="/" />

  useEffect(() => {
    const previewImageEl = document.querySelector('#previewImage')
    html2canvas(previewImageEl).then(canvasOutput => {
      const dataUrl = canvasOutput.toDataURL('image/png')
      setPreviewImageURL(dataUrl)
    })

    const ogImageEl = document.querySelector('#ogImage')
    html2canvas(ogImageEl)
      .then(canvasOutput => {
        imageDataURL = canvasOutput.toDataURL('image/png')

        const formData = new FormData()
        formData.append('file', imageDataURL)
        formData.append('upload_preset', process.env.GATSBY_CLOUDINARY_PRESET)
        const fetchURL = `https://api.cloudinary.com/v1_1/${
          process.env.GATSBY_CLOUDINARY_CLOUDNAME
        }/image/upload`

        return axios.post(fetchURL, formData)
      })
      .then(result => {
        setPhotoURL(get('data.secure_url', result))
        setPhotoID(get('data.public_id', result))
        setIsLoading(false)
      })
      .catch(console.error)
  }, [])

  return (
    <Layout>
      <Heading
        textAlign="center"
        lineHeight="title"
        mt={[4, 8]}
        mb={[2, 4]}
        fontSize={['large', 'xlarge', 'xxlarge']}
      >
        Share your somewhat
        <br />
        heartfelt message
      </Heading>
      <Flex mb={[2, 3]}>
        <RuleCurly sideX="right" mr={[2, 3]} flex="1 1 0px" />
        <RuleCurly sideX="left" flex="1 1 0px" />
      </Flex>
      <Flex justifyContent="center">
        <Box
          flex={[null, '0 0 auto']}
          order={[null, 2]}
          py={[null, 4]}
          width={['100%', 400, 600, 800]}
          position="relative"
        >
          <OutputRenderer />
          <Image
            src={previewImageURL}
            position="absolute"
            width="100%"
            top={[0, '2rem']}
            left={0}
            opacity={previewImageURL ? 1 : 0}
            css={`
              transition: opacity ${p => p.theme.transition};
            `}
          />
        </Box>
      </Flex>
      <Box px={[2, 6]} my={[4, 6]}>
        <ShareButtonsWrapper isLoading={isLoading} />
      </Box>
      <Flex justifyContent="center" px={[2, 6]} mb={[4, 6]}>
        <Button as={Link} to="/">
          Back to Generator
        </Button>
      </Flex>
    </Layout>
  )
}

const mapStateToProps = state => ({
  photoURL: state.output.photoURL,
  hasNoMagnets: compose(
    isEmpty,
    get('magnets.positions')
  )(state),
})

const mapDispatchToProps = dispatch =>
  bindActionCreators({ setPhotoURL, setPhotoID }, dispatch)

const SendPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(SendPageBase)

export default SendPage
